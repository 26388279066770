
const je = require('json-encrypt')
let user_permissions = window.localStorage.getItem("user_permissions");
user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));

var modules = [{
  _name: 'CSidebarNavItem',
  name: 'Inicio',
  to: '/inicio/inicio',
  icon: 'cilHome',
}];


if(user_permissions.indexOf('OrderList') > -1 || user_permissions.indexOf('SaleList') > -1  ){


  let me = this;
  let item = [];
  if (user_permissions.indexOf('OrderList') > -1) {
    item.push({ name: 'Mesas', to: '/pedido/mesas' });
    
  }
  if (user_permissions.indexOf('OrderList') > -1) {
    item.push({ name: 'Pedidos', to: '/pedido/listar'});
  }

  if (user_permissions.indexOf('SaleList') > -1) {
    item.push({ name: 'Comp. Electronicos', to: '/venta/listar'});
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Pedido',
    icon: 'cilCart',
    items: item
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('BoxList') > - 1 || user_permissions.indexOf('ChargeList') > - 1  || user_permissions.indexOf('ExtraordinaryIncomeList') > - 1 || user_permissions.indexOf('ExtraordinaryExpenseList') > - 1 ){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('BoxList') > -1) {
    item.push({ name: 'Listar', to: '/caja/listar' });
  }
  if (user_permissions.indexOf('BoxAdd') > -1) {
    item.push({ name: 'Caja', to: '/caja/nuevo' });
  }
  if (user_permissions.indexOf('ChargeList') > -1) {
    item.push({ name: 'Cobros', to: '/cobro/listar' });
  }
  if (user_permissions.indexOf('ExtraordinaryIncomeList') > -1) {
    item.push({ name: 'Ingresos', to: '/ingreso-extraordinario/listar' });
  }
  if (user_permissions.indexOf('ExtraordinaryExpenseList') > -1) {
    item.push({ name: 'Egresos', to: '/egreso-extraordinario/listar' });
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Caja',
    icon: 'cilCash',
    items: item
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('ReservationList') > -1  ){
  let item = [];
  if (user_permissions.indexOf('ReservationList') > -1) {
    item.push({ name: 'Reserva', to: '/reserva/listar' });
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Reserva',
    icon: 'cilCalendar',
    items: item
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('DeliveryList') > -1  ){
    let TypeUser = {
      _name: 'CSidebarNavItem',
      name: 'Delivery',
      to: '/delivery/listar',
      icon: 'cilStar',
    }
    modules.push(TypeUser);
}

if(user_permissions.indexOf('ClientList') > -1 || user_permissions.indexOf('ProviderList') > -1 ){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('ClientList') > -1) {
    item.push({ name: 'Clientes', to: '/cliente/listar' });
    
  }
  if (user_permissions.indexOf('ProviderList') > -1) {
    item.push({ name: 'Proveedores', to: '/proveedor/listar'});
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Entidades',
    icon: 'cil-people',
    items: item
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('AccountPayList') > - 1 || user_permissions.indexOf('AccountPayReceivableList') > - 1
 ){
  let me = this;
  let item = [];



  // if (user_permissions.indexOf('PaymentList') > -1) {
  //   item.push({ name: 'Pagos', to: '/pago/listar' });
  // }

  if (user_permissions.indexOf('AccountReceivableList') > -1) {
    item.push({ name: 'Cuentas por Cobrar', to: '/cuentas-por-cobrar/listar' });
  }
  if (user_permissions.indexOf('AccountPayList') > -1) {
    item.push({ name: 'Cuentas por Pagar', to: '/cuentas-por-pagar/listar' });
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Finanzas',
    icon: 'cilDollar',
    items: item
  }
  modules.push(TypeUser);
}



if(user_permissions.indexOf('ProductList') > -1 || user_permissions.indexOf('CategoryProductList') > -1
|| user_permissions.indexOf('LetterList') > -1 || user_permissions.indexOf('CategoryLetterList') > -1 ){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('ProductList') > -1) {
    item.push({ name: 'Producto', to: '/producto/listar' });
    
  }
  if (user_permissions.indexOf('CategoryProductList') > -1) {
    item.push({ name: 'Pr. Categoria', to: '/categoria-producto/listar'});
  }

  if (user_permissions.indexOf('LetterList') > -1) {
    item.push({ name: 'Carta', to: '/carta/listar' });
    
  }
  if (user_permissions.indexOf('CategoryLetterList') > -1) {
    item.push({ name: 'Carta Categoria', to: '/categoria-carta/listar'});
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Productos',
    icon: 'cilDrop',
    items: item
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('CateringList') > -1 ){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('ClientList') > -1) {
    item.push({ name: 'Abastecimiento', to: '/abastecimiento/listar' });
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Abastecimiento',
    icon: 'cilStorage',
    items: item
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('RoomList') > - 1 || user_permissions.indexOf('TableList') > - 1 ){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('RoomList') > -1) {
    item.push({ name: 'Salas', to: '/sala/listar' });
  }
  if (user_permissions.indexOf('TableList') > -1) {
    item.push({ name: 'Mesas', to: '/mesa/listar' });
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Salas y Mesas',
    icon: 'cilTask',
    items: item
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('ShoppingList') > - 1 ){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('ShoppingList') > -1) {
    item.push({ name: 'Compra', to: '/compra/listar' });
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Compra',
    icon: 'cilCalculator',
    items: item
  }
  modules.push(TypeUser);
}


if(user_permissions.indexOf('InputList') > - 1 || user_permissions.indexOf('OutputList') > - 1 ){
  let me = this;
  let item = [];

  if (user_permissions.indexOf('InputList') > -1) {
    item.push({ name: 'Entrada', to: '/entrada/listar' });
  }
  if (user_permissions.indexOf('OutputList') > -1) {
    item.push({ name: 'Salida', to: '/salida/listar' });
  }
  if (user_permissions.indexOf('KardexPhysicalList') > -1) {
    item.push({ name: 'Kardex Fisico', to: '/kardex/fisico' });
  }
  if (user_permissions.indexOf('StockGeneralList') > -1) {
    item.push({ name: 'Stock General', to: '/kardex/stock-general' });
  }

  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Almacen',
    icon: 'cilClipboard',
    items: item
  }
  modules.push(TypeUser);
}





if(user_permissions.indexOf('UserList') > -1 || user_permissions.indexOf('UserTypeList') > -1  || user_permissions.indexOf('VoucherList') > -1 || user_permissions.indexOf('BusinessList') > -1 
|| user_permissions.indexOf('WarehouseList') > -1 || user_permissions.indexOf('MosoList') > -1  || user_permissions.indexOf('ExchangeRateList') > -1){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('UserList') > -1) {
    item.push({ name: 'Usuario', to: '/usuario/listar' });
  }
  if (user_permissions.indexOf('UserTypeList') > -1) {
    item.push({ name: 'Tipo de Usuario', to: '/tipo-usuario/listar'});
  }
  // if (user_permissions.indexOf('WarehouseList') > -1) {
  //   item.push({ name: 'Almacenes', to: '/almacen/listar'});
  // }
  if (user_permissions.indexOf('VoucherList') > -1) {
    item.push({ name: 'Comprobante', to: '/comprobante/listar'});
  }
  if (user_permissions.indexOf('ExchangeRateList') > -1) {
    item.push({ name: 'Tipo de Cambio', to: '/tipo-de-cambio/listar'});
  }
  if (user_permissions.indexOf('MosoList') > -1) {
    item.push({ name: 'Moso', to: '/moso/listar'});
  }
  if (user_permissions.indexOf('BusinessList') > -1) {
    item.push({name: 'Empresa',to: '/empresa/editar'});
  }
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Mantenimiento',
    icon: 'cilCog',
    items: item
  }
  modules.push(TypeUser);
}

if(user_permissions.indexOf('ReportOrderList') > -1 || user_permissions.indexOf('ReportBoxList') > -1 
|| user_permissions.indexOf('ReportDeliveryList') > -1 || user_permissions.indexOf('ReportDishList') > -1  ){
  let me = this;
  let item = [];
  if (user_permissions.indexOf('ReportOrderList') > -1) {
    item.push({ name: 'Pedidos', to: '/reporte/pedido' });
  }
  if (user_permissions.indexOf('ReportDeliveryList') > -1) {
    item.push({ name: 'Delivery', to: '/reporte/delivery' });
  }
  if (user_permissions.indexOf('ReportBoxList') > -1) {
    item.push({ name: 'Caja', to: '/reporte/caja' });
  }
  if (user_permissions.indexOf('ReportDishList') > -1) {
    item.push({ name: 'Platos', to: '/reporte/platos' });
  }
  
  let TypeUser = {
    _name: 'CSidebarNavDropdown',
    name: 'Reporte',
    icon: 'cilFile',
    items: item
  }
  modules.push(TypeUser);
}


export default [
  {
    _name: 'CSidebarNav',
    _children: modules
  }
]